<template>
  <div class="flex flex-col">
  
    <div class="form-row w-5/12">
      <div class="form-col  ">
        <label for="resident_name">resident name</label>
        <input  type="text" class="form-input" id="resident_name"/>
      </div>
    </div>
    <div class="form-row w-5/12 my-8">
      <div class="form-col  ">
        <label for="resident_name">contact information</label>
      </div>
    </div>
    <div class="form-row w-5/12  my-8">
      <div class="form-col  ">
        <label for="resident_name">emergency contact</label>
      </div>
    </div>
    <div class="form-row w-5/12  my-8">
      <div class="form-col  ">
        <label for="resident_name">vehicle information</label>
      </div>
    </div>
 
    <modal-footer :footer="footer" :primary="save"/>
  </div>
</template>

<script>
import ModalNavigation from "@/mixins/ModalNavigation";
import NotifyMixin from "@/mixins/NotifyMixin";
// import Icon from '@/components/ui/Icon';
// import OnOff from "@/components/ui/OnOff";
import ModalFooter from '@/components/ui/modals/ModalFooter'

export default {
  name:'transfer resident',
  mixins: [
    ModalNavigation,
    NotifyMixin
  ],
  components:{
    // Icon,
    // OnOff,
    ModalFooter
  },
  data() {
    return {
      residentNames: [1,2,3],
      footer: {
        primaryButton: 'save details',
      },
    }
  },
  methods: {
    save(){
      this.$router.push({name: 'transfer_resident.create-lease'});
    },
  }
}
</script>

<style scoped>
.button-color {
  border-color: var(--highlightColor500);
  color: var(--highlightColor500);
}
</style>